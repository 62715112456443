<template>
  <div class="hot_article">
    <h2>热门文章</h2>
    <ul class="center_title">
      <li v-for="(item, index) in hotArticleList.slice(0,6)" :key="index" @click="skipDetails(item.atUuid)">
        <div class="title">{{ item.title }}</div>
        <span class="date">{{ item.showTime }}</span>
        <span class="sort_num">{{ index + 1 }}</span>
      </li>
    </ul>
    <div class="more">
      <span @click="loadMore">
        <span>查看更多</span>
      <i class="fa fa-caret-right"></i>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "HotArticle",
  data: () => ({
    hotArticleList: []
  }),
  created() {
    this.getHotArticleList()
  },
  methods: {
    getHotArticleList() {
      let params = {
        currentPage: 1,
        currentPageSize: 6,
        type: ''
      }
      this.axios.get('/index/article-hot', {params}).then(res => {
        this.hotArticleList = res.page.list
      })
    },
    // 跳转详情
    skipDetails(atUuid) {
      let routeUrl = this.$router.resolve({path: '/article-detail/' + atUuid});
      window.open(routeUrl.href, '_blank');
    },
    // 加载更多
    loadMore() {
      let menuActive = this.$store.state.menuActive
      let curRouter = this.$route.path
      if (menuActive == 0) {
        document.documentElement.scrollTop = 567
      }
      if (menuActive == 1 || curRouter != '/index') {
        this.$router.push({
          path: '/index',
          name: 'Home',
          params: {
            isScroll: true
          }
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// 热门文章
.hot_article {
  width: 100%;
  min-height: 500px;
  background: #FFFFFF;
  border-radius: 4px;
  padding: 14px 20px 20px 50px;
  box-sizing: border-box;

  h2 {
    text-align: center;
    position: relative;
    font-size: 24px;
    font-weight: 600;
    left: -20px;
    margin-bottom: 20px !important;
  }

  .center_title {
    list-style: none;
    margin: 20px 0;
    padding: 0;

    li {
      margin-top: 10px;
      margin-bottom: 20px;
      position: relative;
      cursor: pointer;

      .sort_num {
        position: absolute;
        width: 20px;
        height: 20px;
        position: absolute;
        background: #3E35E7;
        color: #FFFFFF;
        line-height: 20px;
        text-align: center;
        border-radius: 4px;
        left: -30px;
        top: 10px;
      }

      &:nth-child(2) .sort_num {
        opacity: 0.8;
      }

      &:nth-child(3) .sort_num {
        opacity: 0.6;
      }

      &:nth-child(4) .sort_num {
        opacity: 0.4;
      }

      &:nth-child(5) .sort_num, &:nth-child(6) .sort_num {
        opacity: 0.2;
      }

      &:after {
        content: '';
        width: 114%;
        height: 1px;
        background: #E1E1E1;
        position: absolute;
        bottom: 0;
        left: -30px;
      }

      .title {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 31px;
        color: #000000;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }

      .date {
        font-size: 14px;
        color: #999999;
        font-family: Source Han Sans CN;
        line-height: 40px;
      }

    }

  }

  .more {
    text-align: center;
    color: #3E35E7;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    line-height: 35px;
    cursor: pointer;
    position: relative;
    left: -20px;
    user-select: none;
    font-size: 0.9rem;

    .fa {
      margin-left: 6px;
      font-size: 18px;
      line-height: 35px;
      position: relative;
      top: 1px;
    }
  }
}
</style>
